import React, { createContext, useState } from 'react';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const login = (userData) => {
    // Perform login logic, e.g., making API calls for authentication
    // Once authenticated, set the user data in the state
    setUser(userData);
  };

  const logout = () => {
    // Perform logout logic, e.g., clearing user data from state or removing tokens
    setUser(null);
  };

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
