import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./LoginPage";
import RegistrationPage from "./RegistrationPage";
import HomePage from "./HomePage";
import NewForm from "./NewForm";
import Header from "./Header";
import Footer from "./Footer";
import UserDetails from "./userVerification";

const App = () => {
  const isUserDetailsLocation = window.location.pathname !== '/getuserdetails';
  return (
    <Router basename={"/"}>
      <div style={{ display: "flex", flexDirection: "column" }}>
      {isUserDetailsLocation ? <Header /> : null}
        <div style={{ flex: 1 }}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegistrationPage />} />
            <Route path="/form" element={<NewForm />} />
            <Route path="/getuserdetails" element={<UserDetails />} />
          </Routes>
        </div>
        {isUserDetailsLocation ? <Footer /> : null}
      </div>
    </Router>
  );
};

export default App;
