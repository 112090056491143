import React, { useContext, useState } from 'react';
import { useNavigate  } from 'react-router-dom';
import { Typography, TextField, Button, Grid, Paper } from '@material-ui/core';
import { UserContext } from './UserContext';
import axios from 'axios';

const LoginPage = () => {
  const { login } = useContext(UserContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); 
  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      // Perform login logic, e.g., making API calls for authentication
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, { email, password });
      const userData = response.data; // Assuming the response contains user data
  
      // Once authenticated, call the login function from UserContext and pass user data
      login(userData);
  
      // Redirect to the home page
      navigate('/');
    } catch (error) {
      console.error("Error:", error.message);
      // Handle authentication error and show an error message to the user if necessary
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "calc(100vh - 64px)" }}>
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Paper style={{ padding: '2rem' }}>
          <Typography variant="h5" gutterBottom>
            Login
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  type="email"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Password"
                  type="password"
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{backgroundColor: '#212121'}}
                  type="submit"
                  fullWidth
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
