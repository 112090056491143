import React from "react";
import Select from "react-select";
import { fallbackCountries } from "./constants";

const CountrySelect = ({ selectedCountry, setSelectedCountry }) => {
  // const [countries, setCountries] = useState([]);

  // useEffect(() => {
  //   const fetchCountries = async () => {
  //     try {
  //       const response = await fetch(
  //         "https://valid.layercode.workers.dev/list/countries?format=select&flags=false&value=code"
  //       );
  //       if (!response.ok) throw new Error("API request failed");
  //       const data = await response.json();
  //       setCountries(data.countries);
  //     } catch (error) {
  //       console.error("Fetching countries failed, using fallback data:", error);
  //       setCountries(fallbackCountries.countries); // Use fallback data on failure
  //     }
  //   };

  //   fetchCountries();
  // }, []);

  return (
    <Select
      options={fallbackCountries.countries}
      value={[fallbackCountries.countries].find((country) => country.value === selectedCountry)}
      onChange={(selectedOption) => setSelectedCountry(selectedOption)}
    />
  );
};

export default CountrySelect;
