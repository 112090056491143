import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  makeStyles,
  IconButton,
  Box,
  Card,
} from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import Header from "./Header";
const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "calc(100vh - 64px)",
  },
  card: {
    position: "relative", // Make the card position relative
    width: "20%",
    marginBottom: "64px",
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
    "@media print": {
      pageBreakInside: "avoid",
    },
  },
  headerCell: {
    fontWeight: "bold",
    color: "#212121",
  },
  detailCell: {
    paddingLeft: "20px",
    color: "#212121",
  },
  printIcon: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  "@media print": {
    printHeader: {
      display: "none",
    },
    printEmail: {
      display: "none",
    },
    printCity: {
      display: "none",
    },
    cardPrint: {
      width: "100%",
    },
    detailCellPrint: {
      // Additional styling for detail cells when printing, e.g., font size
    },
  },
}));

const UserDetails = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("userid");
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch user details based on the user ID from the query parameter in the URL
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/userdetails?userid=${userId}`
        );
        setUserData(response.data[0]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // Handle the error and show an error message to the user if necessary
      }
    };

    if (userId) {
      fetchUserDetails();
    }
  }, [userId]);

  if (!userId) {
    return <Typography>No user ID found in the URL.</Typography>;
  }

  // Check if loading is true, display loading message
  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  // Check if userData is empty, display no data message
  if (!userData) {
    return (
      <>
        <div style={{ margin: "auto", height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography>No data found.</Typography>
        </div>
      </>
    );
  }

  const handlePrint = () => {
    window.print();
  };

  return (
    <>
      {location.pathname !== "/getUserDetails" ? null : <Header />}
      <div className={classes.cardContainer}>
        <Typography
          style={{ textAlign: "center", fontWeight: 600, fontSize: "20px" }}
        >{`${userData.firstName.toUpperCase()} ${userData.lastName.toUpperCase()}`}</Typography>
        <Typography style={{ textAlign: "center", fontSize: "16px" }}>
          {userData.companyName.toUpperCase()}
        </Typography>
      </div>
    </>
  );
};

export default UserDetails;
