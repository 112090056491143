import React, { useContext } from "react";
import { AppBar, Toolbar, makeStyles, Button, Grid } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "./UserContext";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#fff", // Update background color to dark theme
  },
  logo: {
    maxHeight: 60,
    marginRight: theme.spacing(2),
    cursor: "pointer", // Add cursor pointer to indicate it's clickable
  },
  loginButton: {
    marginLeft: "auto", // Move login button to the right side
  },
}));

const Header = () => {
  const classes = useStyles();
  const { user, logout } = useContext(UserContext);

  const handleLogout = () => {
    logout(); // Call the logout function from UserContext
  };

  return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item>
              <Link
                to="https://www.internationalapparelandtextilefair.com/"
                style={{ textDecoration: "none" }}
              >
                <img
                  src="https://www.internationalapparelandtextilefair.com/assets/images/ITF%20Website_Logo-06.png"
                  alt="Logo"
                  className={classes.logo}
                />
              </Link>
            </Grid>
            <Grid item xs />
            <Grid item>
              {user ? (
                <Button
                  color="#212121"
                  className={classes.loginButton}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              ) : (
                <Button color="#212121" component={Link} to="/login">
                  Admin Login
                </Button>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
  );
};

export default Header;
