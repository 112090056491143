import React from 'react';
import { Typography } from '@material-ui/core';

const Footer = () => {
  return (
    <footer style={{ 
      backgroundColor: '#f5f5f5',
      padding: '1rem',
      textAlign: 'center',
      left: 0,
      bottom: 0,
    }}>
      <Typography variant="body2" color="textSecondary">
        © 2024 INTERNATIONAL APPAREL & TEXTILE FAIR, DUBAI, UAE. ALL RIGHTS RESERVED
      </Typography>
    </footer>
  );
};

export default Footer;
